
import { defineComponent, onUnmounted } from 'vue'
import mitt, { Handler } from 'mitt'
type ValidateFunc = () => boolean
// 实例化 mitt
export const emitter = mitt()
export default defineComponent({
  emits: ['form-submit'],
  setup (props, context) {
    let funcArr: ValidateFunc[] = []
    const submitForm = () => {
      // 循环执行数组 得到最后的验证结果
      const result = funcArr.map(func => func()).every(result => result)
      context.emit('form-submit', result)
    }
    // 将监听得到的验证函数都存到一个数组中
    const callback = (func: ValidateFunc) => {
      funcArr.push(func)
    }
    // 添加监听
    // eslint-disable-next-line no-undef
    emitter.on('form-item-created', callback as Handler<unknown>)
    onUnmounted(() => {
      // 删除监听
      emitter.off('form-item-created', callback as Handler<unknown>)
      funcArr = []
    })
    return {
      submitForm
    }
  }
})
