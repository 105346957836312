
import { computed, defineComponent, onMounted } from 'vue'
import ColumnList from '../components/QuotesList.vue'
import CarouselList from '@/components/CarouselList.vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
export default defineComponent({
  name: 'Home',
  components: {
    ColumnList,
    CarouselList
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    onMounted(() => {
      store.dispatch('fetchQutoes')
    })
    const list = computed(() => store.state.quotes)
    const carouseilist = computed(() => {
      const wallpapers = store.state.wallpaper
      return wallpapers.slice(0, 3) // 只取前两张图片
    })
    // 定义完毕，就可以在应用中使用这个 getter 了
    // Getter 会暴露为 store.getters 对象，你可以以属性的形式访问这些值：
    // const biggerColumnsLen = computed(() => store.getters.biggerColumnsLen)
    console.log('list', computed(() => store.state.quotes.length))
    return {
      list,
      carouseilist
    }
  }
})
