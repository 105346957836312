export interface ColumnProps {
  id: string;
  content: string;
  author: string;
  theme: string;
  likenumber: number;
}
export interface PostProps {
  id: number;
  title: string;
  content: string;
  image?: string;
  createdAt: string;
  columnId: number;
}
export interface ThemeProps {
  id: string;
  themeName: string;
}

export interface Wallpaper {
  id: string;
  wallpaperName: string;
  wallpaperUrl: string;
  hash: string;
  createtime: string;
  userId: string;
  timestamp: string;
}
export const testData: ColumnProps[] = [
  {
    id: '1',
    content: '一个人无论做出多少件事来，我们都可以在里面认出同样的性格。',
    author: '（美）爱默生',
    theme: 'test',
    likenumber: 100
  }
]

export const testPosts: PostProps[] = [
  {
    id: 1,
    title: '这是我的第一篇文章',
    content: '"this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif..."',
    image: 'http://vue-maker.oss-cn-hangzhou.aliyuncs.com/vue-marker/5ee1980819f4ae08ac78d458.png?x-oss-process=image/resize,m_fill,m_pad,w_200,h_110',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  },
  {
    id: 2,
    title: '这是我的第二篇文章',
    content: '"this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif..."',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  },
  {
    id: 3,
    title: '这是我的第三篇文章',
    content: '"this is a new post you Very often we will need to map routes with the given pattern to the same component. For example we may have a User component which should be rendered for all users but with dif..."',
    image: 'https://vue-maker.oss-cn-hangzhou.aliyuncs.com/vue-marker/5edcc2329f2b4e28352b75eb.jpg?x-oss-process=image/resize,m_fill,m_pad,w_200,h_110',
    createdAt: '2020-06-11 10:34:22',
    columnId: 1
  }
]
export const testWallpaperData: Wallpaper[] = [
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    wallpaperName: '好格言首页图片',
    wallpaperUrl: 'https://s2.loli.net/2024/01/24/tcMHLZmbdDxya3w.png',
    hash: 'string',
    createtime: '2024年1月15日',
    userId: '0491d114c5b341758c99879c1eb72877',
    timestamp: '1703739987.116305'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    wallpaperName: '好格言首页图片',
    wallpaperUrl: 'https://s2.loli.net/2024/01/24/tcMHLZmbdDxya3w.png',
    hash: 'string',
    createtime: '2024年1月15日',
    userId: '0491d114c5b341758c99879c1eb72877',
    timestamp: '1703739987.116305'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    wallpaperName: '好格言首页图片',
    wallpaperUrl: 'https://s2.loli.net/2024/01/24/tcMHLZmbdDxya3w.png',
    hash: 'string',
    createtime: '2024年1月15日',
    userId: '0491d114c5b341758c99879c1eb72877',
    timestamp: '1703739987.116305'
  }
]
export const testThemeProps: ThemeProps[] = [
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '为人'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试2'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试3'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e043',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0343',
    themeName: '测试'
  },
  {
    id: 'b79194d6e594deba8fc0ced8f68e0423',
    themeName: '测试'
  }
]
