import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "column-detail-page w-75 mx-auto" }
const _hoisted_2 = {
  key: 0,
  class: "column-info row mb-4 border-bottom pb-4 align-items-center"
}
const _hoisted_3 = { class: "col-3 text-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-9" }
const _hoisted_6 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_list = _resolveComponent("post-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.column)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.column.avatar,
              alt: _ctx.column.title,
              class: "rounded-circle border w-100"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.column.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.column.description), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_post_list, { list: _ctx.list }, null, 8, ["list"])
  ]))
}