import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container home-page",
  style: {"margin-top":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_theme_list = _resolveComponent("theme-list")!
  const _component_quotes_list = _resolveComponent("quotes-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_theme_list, { themes: _ctx.themeList }, null, 8, ["themes"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_quotes_list, { list: _ctx.list }, null, 8, ["list"])
    ])
  ], 64))
}