
import { defineComponent, PropType, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Dropdown from '@/components/Dropdown.vue'
import DropdownItem from '@/components/DropdownItem.vue'
import store from '@/store'
export interface UserProps {
  isLogin: boolean;
  name?: string;
  id?: number;
}

export default defineComponent({
  name: 'GlobalHeader',
  components: {
    // Dropdown,
    // DropdownItem
  },
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup () {
    const searchContent = ref('')
    const router = useRouter()
    const searchType = ref('content') // Define searchType
    const searchKeyword = ref('') // Define searchKeyword
    // const searchQuotes = (content: string) => {
    //   // showingCurrentTheme.value = false
    //   store.dispatch('SearchQuotesByContent', { content, skip: 0, limit: 150 })
    //   // 搜索后重定向到搜索路由
    //   if (route.name !== 'search') {
    //     router.push({ name: 'search', params: { content } })
    //   }
    // }
    const searchQuotes = (searchKeyword:string) => {
      // showingCurrentTheme.value = false
      let searchAction = ''
      let type = 0
      if (searchType.value === 'content') {
        searchAction = 'SearchQuotesByContent'
        type = 0
      } else if (searchType.value === 'author') {
        searchAction = 'SearchQuotesByAuthor'
        type = 1
      }
      store.dispatch(searchAction, { content: searchKeyword, skip: 0, limit: 150 })
      router.push({ name: 'search', params: { searchcontent: searchKeyword, type: type } })
      // 搜索后重定向到搜索路由
      // if (route.name !== 'search') {
      //   router.push({ name: 'search', params: { searchcontent: searchKeyword } })
      // }
    }

    return {
      searchContent,
      searchQuotes,
      searchKeyword,
      searchType
    }
  }
})
