import { createRouter, createWebHistory } from 'vue-router'
import { createStore } from 'vuex'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import ColumnDetail from '@/views/ColumnDetail.vue'
import QuotesByTheme from '@/views/QuotesTheme.vue'
import search from '@/views/search.vue'
import NotFind from '@/views/NotFind.vue'
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      // 动态路由
      path: '/column/:id',
      name: 'column',
      component: ColumnDetail
    },
    {
      // 动态路由
      path: '/themes',
      name: 'themes',
      component: QuotesByTheme,
      meta: {
        description: '父母、气度、礼貌、乐观、诚信、虚荣、嫉妒、养生、方法、帮助、朋友、言行、进取、美德、失败、节俭、生命、科学、善良、求知、处事、休闲、金钱、运动、理想、疾病、财富、价值、时尚、实践、思考、情趣、事业、思想、愚蠢、用才、自尊、辩证、创新、目标、健康、生活、习惯、教育、技术、创业、命运、伟人、人才、读书、愁绪、坚持、工作、信心、智慧、爱国、快乐、自省、责任、惜时、书籍、竞争、正直、宽容、稳重、贪婪、合作、修身、激情、爱情、理智、成功、交际、谦虚、奉献、娱乐、旅行、变革、希望、信仰、勇敢、奋斗、个性、良心、欲望、立志、心灵、成才、饮食、才智、享受、意志、机遇、记忆、品格、子女、逆境、人生、行动、生死、亲情相关的名人名言-格言'
      }
    },
    {
      // 动态路由
      path: '/search/:searchcontent/:type',
      name: 'search',
      component: search
    },
    // 添加一个捕捉所有未匹配路由的配置
    {
      path: '/:catchAll(.*)',
      component: NotFind
    }
  ]
})
router.afterEach((to, from) => {
  // 使用 window.scroll 方法确保在不同浏览器中都能正常工作
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth' // 如果需要平滑滚动，可以使用 'smooth'
  })
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.description) {
    const descriptionTag = document.querySelector('meta[name="description"]')
    if (descriptionTag) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      descriptionTag.setAttribute('content', to.meta.description)
    } else {
      // 如果不存在 <meta name="description"> 标签，则创建并添加到 head 中
      const newDescriptionTag = document.createElement('meta')
      newDescriptionTag.name = 'description'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newDescriptionTag.content = to.meta.description
      document.head.appendChild(newDescriptionTag)
    }
  }

  next()
})

export default router
