import { createStore, Commit } from 'vuex'
import { testData, testPosts, PostProps, testWallpaperData, Wallpaper, ThemeProps, testThemeProps } from './testData'
import axios from 'axios'
interface UserProps {
  isLogin: boolean;
  name?: string;
  id?: number;
}
export interface Quotes {
  id: string;
  content: string;
  author: string;
  theme: string;
  likenumber: number;
}
export interface GlobalDataProps {
  loading: boolean;
  quotes: Quotes[];
  ips:[];
  posts: PostProps[];
  user: UserProps;
  wallpaper: Wallpaper[];
  ThemeProps: ThemeProps[];
}
const getAndCommit = async (url:string, mutationName:string, commit:Commit) => {
  const { data } = await axios.get(url)
  commit(mutationName, data)
}
const store = createStore<GlobalDataProps>({
  state: {
    loading: false,
    quotes: [],
    ips: [],
    posts: testPosts,
    user: { isLogin: false },
    wallpaper: testWallpaperData,
    ThemeProps: testThemeProps
  },
  mutations: {
    login (state) {
      state.user = { ...state.user, isLogin: true, name: '小哆啦' }
    },
    fetchQutoes (state, rawData) {
      state.quotes = rawData.data
      // console.log('rawData.data', rawData.data)
    },
    // 这个值会更新页面上的显示
    updateLikeNumber (state, id) {
      // 在这里更新对应 id 的点赞数量，可以从 state.columns 中找到对应的项
      const column = state.quotes.find(c => c.id === id)
      if (column) {
        // 在这里进行简单的+1操作，可以根据后端的实际返回情况进行修改
        column.likenumber += 1
      }
    },
    setLoading (state, status) {
      state.loading = status
    },
    fetchQuotesByTheme (state, rawData) {
      state.quotes = rawData.data
      console.log('rawData.data', rawData.data)
      console.log('state.quotes', state.quotes)
    },
    fetchThemes (state, rawData) {
      state.ThemeProps = rawData.data
      console.log('ThemesData.data', rawData.data)
    },
    SearchQuotesByContent (state, rawData) {
      state.quotes = rawData.data
      console.log('ThemesData.data', rawData.data)
    },
    SearchQuotesByAuthor (state, rawData) {
      state.quotes = rawData.data
      console.log('ThemesData.data', rawData.data)
    },
    SearchQuotesByType (state, rawData) {
      state.quotes = rawData.data
      console.log('ThemesData.data', rawData.data)
    },
    fetchAllips (state, rawData) {
      state.ips = rawData.data
      console.log('ThemesData.data', rawData.data)
    },
    recordIp (state, rawData) {
      console.log('recordIp.data')
    }
  },
  actions: {
    async fetchQutoes ({ commit }) {
      getAndCommit('/api/qutoes/show_quotes?skip=0&limit=4', 'fetchQutoes', commit)
    },
    async recordIp ({ commit }) {
      try {
        // 发送 POST 请求到 FastAPI 后端
        const response = await axios.post('/api/themes/record-ip')
        // 处理响应，如果需要的话
        console.log(response.data)
      } catch (error) {
        // 处理错误，如果需要的话
        console.error(error)
      }
    },
    async fetchAllips ({ commit }) {
      getAndCommit('/api/themes/get-all-and-today-ips', 'fetchAllips', commit)
    },
    async updateLikeNumber  ({ commit }, QuotesId: string) {
      await axios.post(`/api/qutoes/update-likenumber/${QuotesId}`)
      commit('updateLikeNumber', QuotesId)
    },
    async fetchThemes ({ commit }) {
      getAndCommit('/api/themes/show_themes?skip=0&limit=150', 'fetchThemes', commit)
    },
    async fetchQuotesByTheme ({ commit }, { themename, skip, limit }) {
      const response = await axios.get(`/api/qutoes/show_quotes_by_theme/${themename}?skip=${skip}&limit=${limit}`)
      console.log(themename)
      commit('fetchQuotesByTheme', response.data)
    },
    async SearchQuotesByContent ({ commit }, { content, skip, limit }) {
      store.commit('setLoading', true)
      const response = await axios.get(`/api/qutoes/search_quotes_bycontent?content=${content}&skip=${skip}&limit=${limit}`)
      console.log(content)
      commit('SearchQuotesByContent', response.data)
      store.commit('setLoading', false)
    },
    async SearchQuotesByAuthor ({ commit }, { content, skip, limit }) {
      store.commit('setLoading', true)
      const response = await axios.get(`/api/qutoes/search_quotes_auther?author=${content}&skip=${skip}&limit=${limit}`)
      // console.log(content)
      commit('SearchQuotesByAuthor', response.data)
      store.commit('setLoading', false)
    },
    async SearchQuotesByType ({ commit }, { content, skip, limit }) {
      store.commit('setLoading', true)
      const response = await axios.get(`/api/qutoes/search_quotes_bytype?type=${content}&skip=${skip}&limit=${limit}`)
      // console.log(content)
      commit('SearchQuotesByType', response.data)
      store.commit('setLoading', false)
    }
  },
  // Vuex 允许我们在 store 中定义“getter”（可以认为是 store 的计算属性）。就像计算属性一样，getter 的返回值会根据它的依赖被缓存起来，且只有当它的依赖值发生了改变才会被重新计算。
  getters: {
    // biggerColumnsLen (state) {
    //   return state.quotes.filter(c => c.id > 2).length
    // },
    // getColumnById: (state) => (id: number) => {
    //   return state.quotes.find(c => c.id === id)
    // },
    getPostsByCid: (state) => (id: number) => {
      return state.posts.filter(post => post.columnId === id)
    }
  }
})

export default store
