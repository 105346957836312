
import { defineComponent, PropType, ref, computed, onMounted } from 'vue'
import { ThemeProps } from '@/testData' // 假设有一个名为 ThemeProps 的数据类型
import store from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ThemeList',
  props: {
    themes: {
      required: true,
      type: Array as PropType<ThemeProps[]>
    }
  },
  setup (props) {
    const currentThemeName = ref<string | null>(null)
    const showingCurrentTheme = ref(false) // 新增的布尔值
    // 折叠状态
    const isCollapsed = ref(true)
    // 折叠和展开的方法
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value
    }
    console.log('props.themes', props.themes)
    const displayRows = ref(2)
    const showMoreButton = ref(true)
    const displayedThemes = computed(() => props.themes.slice(0, displayRows.value * 6))
    const QutoesByTheme = (themename: string) => {
      console.log('Clicked theme:', themename)
      currentThemeName.value = themename
      showingCurrentTheme.value = true // 设置为 true，表示正在查看专栏
      store.dispatch('fetchQuotesByTheme', { themename, skip: 0, limit: 150 })
    }
    const toggleMore = () => {
      displayRows.value += 2
      if (displayRows.value * 6 >= props.themes.length) {
        showMoreButton.value = false
      }
    }
    const isLastRow = computed(() => {
      const lastRowStartIndex = (displayRows.value - 1) * 6
      return displayedThemes.value.length >= lastRowStartIndex + 3
    })
    const router = useRouter()
    // const navigateToTheme = (themename: string) => {
    //   // 使用路由导航到对应主题的页面
    //   router.push({ name: 'themes', params: { themename } })
    //   currentThemeName.value = themename
    //   showingCurrentTheme.value = true // 设置为 true，表示正在查看专栏
    //   store.dispatch('fetchQuotesByTheme', { themename, skip: 0, limit: 150 })
    // }
    console.log('displayedThemes', displayedThemes)
    return {
      displayedThemes,
      showMoreButton,
      toggleMore,
      isLastRow,
      QutoesByTheme,
      isCollapsed,
      toggleCollapse,
      currentThemeName,
      showingCurrentTheme
    }
  }
})
