
import { computed, defineComponent, PropType, watchEffect } from 'vue'
import { Wallpaper, testWallpaperData } from '@/testData'
export default defineComponent({
  name: 'CarouseIList',
  props: {
    list: {
      type: Array as PropType<Wallpaper[]>,
      require: true
    }
  },
  setup (props) {
    const CarouseIList = computed(() => {
      return props.list?.map(wallpaper => {
        if (!wallpaper.wallpaperUrl) {
          wallpaper.wallpaperUrl = require('@/assets/background.webp')
        }
        return wallpaper
      })
    })
    const carouseList = CarouseIList.value
    watchEffect(() => {
      if (carouseList) {
        carouseList.forEach((wallpaper) => {
          preloadImage(wallpaper.wallpaperUrl)
        })
      }
    })

    // 预加载图片的函数
    function preloadImage (url: string) {
      const img = new Image()
      img.src = url
    }
    return {
      carouseList
    }
  }
})
