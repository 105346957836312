
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    changePage: {
      type: Function,
      required: true
    },
    maxVisiblePages: {
      type: Number,
      default: 3 // 可见的最大页码数量
    }
  },
  computed: {
    // 计算显示的页码数组
    displayedPages (): number[] {
      const pages: number[] = []
      const total = this.totalPages
      const current = this.currentPage
      const maxVisible = this.maxVisiblePages

      // 计算省略号前的页码
      let start = Math.max(1, current - Math.floor(maxVisible / 2))

      // 计算省略号后的页码
      const end = Math.min(total, start + maxVisible - 1)
      start = Math.max(1, end - maxVisible + 1)
      // 添加页码到数组
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      return pages
    },
    // 是否显示省略号前的页码
    showStartEllipsis (): boolean {
      return this.displayedPages.length > 0 && this.displayedPages[0] > 1
    },
    // 是否显示省略号后的页码
    showEndEllipsis (): boolean {
      return (
        this.displayedPages.length > 0 && this.displayedPages[this.displayedPages.length - 1] < this.totalPages
      )
    }
  }
})
