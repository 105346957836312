
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import { testData, testPosts } from '@/testData'
import PostList from '@/components/PostList.vue'

export default defineComponent({
  components: {
    PostList
  },
  setup () {
    const route = useRoute()
    // string 转换 number
    const currentId = +route.params.id
    // eslint-disable-next-line eqeqeq
    // const column = testData.find(c => c.id == currentId)
    // // eslint-disable-next-line eqeqeq
    // const list = testPosts.filter(post => post.columnId == currentId)
    // 定义完毕以后就可以在应用中使用 getter 快速的拿到这两个值了
    const column = computed(() => store.getters.getColumnById(currentId))
    const list = computed(() => store.getters.getPostsByCid(currentId))
    return {
      column,
      list,
      route
    }
  }
})
