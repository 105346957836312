
import { computed, defineComponent, onMounted, ref } from 'vue'
import QuotesList from '../components/QuotesList.vue'
import ThemeList from '@/components/ThemeList.vue'
import { useStore } from 'vuex'
import store, { GlobalDataProps } from '@/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'Quotes',
  components: {
    QuotesList,
    ThemeList
  },
  setup () {
    const route = useRoute()
    // const initialThemename = route.params.themename
    // const themename = ref(initialThemename)
    // const themename = computed(() => route.params.themename)
    // console.log('sedfaasdfdsaf', themename.value)
    const store = useStore<GlobalDataProps>()
    onMounted(() => {
      // store.dispatch('fetchQuotesByTheme', { themename: themename.value, skip: 0, limit: 150 })
      store.dispatch('fetchQutoes')
      store.dispatch('fetchThemes')
    })
    const list = computed(() => store.state.quotes)
    console.log('listtest', list)
    // 定义完毕，就可以在应用中使用这个 getter 了
    // Getter 会暴露为 store.getters 对象，你可以以属性的形式访问这些值：
    // const biggerColumnsLen = computed(() => store.getters.biggerColumnsLen)
    console.log('list', list)
    const themeList = computed(() => store.state.ThemeProps)
    console.log('themeList', themeList)
    return {
      list,
      themeList
    }
  }
})
