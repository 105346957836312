
import { computed, defineComponent, provide, ref, onMounted } from 'vue'
import ColumnList from './components/QuotesList.vue'
import GlobalHeader, { UserProps } from '@/components/GlobalHeader.vue'
import { useStore } from 'vuex'
import { Toast } from 'bootstrap'
import { GlobalDataProps } from '@/store'
export default defineComponent({
  name: 'App',
  components: {
    GlobalHeader
    // Login
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const list = computed(() => store.state.ips)
    console.log(list)
    // 折叠状态
    // const isCollapsed = ref(true)
    // const istoggleDisclaimer = ref(true)
    // 折叠和展开的方法
    const toggleCollapse = () => {
      const toastLiveExample = document.getElementById('testabout')
      if (toastLiveExample) {
        const toast = new Toast(toastLiveExample)
        toast.show()
      }
      // isCollapsed.value = !isCollapsed.value
    }
    const toggleDisclaimer = () => {
      // isCollapsed.value = !isCollapsed.value
      const toastLiveExample = document.getElementById('mianze')
      if (toastLiveExample) {
        const toast = new Toast(toastLiveExample)
        toast.show()
      }
    }
    onMounted(() => {
      store.dispatch('recordIp')
      store.dispatch('fetchAllips')
    })
    const stors = useStore()
    const currentUser = computed(() => stors.state.user)
    const isLoading = computed(() => stors.state.loading)
    return {
      // list: testData,
      currentUser: currentUser,
      isLoading,
      // isCollapsed,
      toggleCollapse,
      toggleDisclaimer,
      list
      // istoggleDisclaimer
    }
  }

})
