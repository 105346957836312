
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalHeader, { UserProps } from '@/components/GlobalHeader.vue'
import ValidateForm from '@/components/ValidateForm.vue'
import validateInput, { RulesProp } from '@/components/ValidateInput.vue'
import { useStore } from 'vuex'
const emailReg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export default defineComponent({
  name: 'App',
  components: {
    // ColumnList,
    validateInput,
    ValidateForm
  },
  setup () {
    const inputRef = ref<any>()
    const emailVal = ref('wzk@gmail.com')
    const router = useRouter()
    const store = useStore()
    const emailRules: RulesProp = [
      { type: 'required', message: '电子邮箱地址不能为空' },
      { type: 'email', message: '请输入正确的电子邮箱格式' }
    ]
    const passwordVal = ref('')
    const passwordRules: RulesProp = [
      { type: 'required', message: '密码不能为空' }
    ]
    const emailRef = reactive({
      val: '',
      error: false,
      message: ''
    })
    const validateEail = () => {
      // eslint-disable-next-line eqeqeq
      if (emailRef.val.trim() == '') {
        emailRef.error = true
        emailRef.message = 'can not be empty'
      } else if (!emailReg.test(emailRef.val)) {
        emailRef.error = true
        emailRef.message = 'should be valid message'
      }
    }
    const onFormSubmit = (result: boolean) => {
      if (result) {
        // useRouter 的使用 登录成功跳转
        // router.push({ name: 'column', params: { id: 1 } })
        router.push('/')
        store.commit('login')
      }
    }
    return {
      emailRef,
      validateEail,
      emailRules,
      emailVal,
      passwordVal,
      passwordRules,
      onFormSubmit,
      inputRef
    }
  }

})
