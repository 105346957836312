import { createApp } from 'vue'
import router from '@/router'
import store from '@/store'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import axios from 'axios'
const app = createApp(App)
// app.use(ElementPlus)
app.use(router)
app.use(store)
axios.defaults.baseURL = 'https://www.goodsaying.cn/'
// axios.defaults.baseURL = 'http://127.0.0.1:8996'
// axios.defaults.baseURL = 'http://110.41.59.220:8882/'
// 不用全部拦截，手动拦截
// axios.interceptors.request.use(config => {
//   store.commit('setLoading', true)
//   return config
// })
// axios.interceptors.response.use(config => {
//   store.commit('setLoading', false)
//   return config
// })
// axios.get('/api/qutoes/show_quotes?skip=0&limit=100').then(resp => {
//   console.log(resp.data)
// })
app.mount('#app')
