
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import ColumnList from '../components/QuotesList.vue'
import CarouselList from '@/components/CarouselList.vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'Home',
  components: {
    ColumnList
  },
  setup () {
    const route = useRoute()
    // const searchType = ref(Number(route.params.type))
    // const searchContent = ref(route.params.searchcontent)
    // onMounted(() => {
    //   const searchType1 = computed(() => Number(route.params.type)) // 将字符串转换为数字
    //   const searchContent1 = computed(() => route.params.searchcontent)
    //   console.log('1', searchType1)
    //   console.log('2', searchContent1)
    //   if (searchType1.value === 0) {
    //     store.dispatch('SearchQuotesByContent', { content: searchContent1.value, skip: 0, limit: 150 })
    //   } else if (searchType1.value === 1) {
    //     store.dispatch('SearchQuotesByAuthor', { content: searchContent1.value, skip: 0, limit: 150 })
    //   } else if (searchType1.value === 3) {
    //     store.dispatch('SearchQuotesByType', { content: searchContent1.value, skip: 0, limit: 150 })
    //   }
    // })
    const store = useStore<GlobalDataProps>()
    const list = computed(() => store.state.quotes)
    const isLodaing = computed(() => store.state.loading)
    // const searchContent = computed(() => route.query.content || '')
    const searchContent = computed(() => route.params.searchcontent)
    const searchType = computed(() => Number(route.params.type))
    const fetchData = () => {
      if (searchType.value === 0) {
        store.dispatch('SearchQuotesByContent', { content: searchContent.value, skip: 0, limit: 150 })
      } else if (searchType.value === 1) {
        store.dispatch('SearchQuotesByAuthor', { content: searchContent.value, skip: 0, limit: 150 })
      } else if (searchType.value === 3) {
        store.dispatch('SearchQuotesByType', { content: searchContent.value, skip: 0, limit: 150 })
      }
    }
    const updatePageTitle = () => {
      let title = '好格言网-经典格言|名言|诗句|美句'
      let h1 = '欢迎来到好格言 - 发现生活的智慧、哲理'
      let description = '精选世界经典格言-名人名言-警句-诗词-美句。各种类型-主题的句子-让你的生活更有意义。'
      let keywords = '名言名句-人生格言-读书名言-名人名言-励志名言-名言集-励志名言警句-爱情名言-激励名言-名言名句大全'
      if (searchContent.value && searchType.value !== undefined) {
        h1 = `${searchContent.value}相关的句子 诗歌 格言 诗词 名人名言`
        title = `${searchContent.value}相关的句子 诗歌 格言 诗词 名人名言`
        description = `与${searchContent.value}相关的句子 诗歌 格言 诗词 名人名言`
        keywords = `${searchContent.value} 诗歌 名言名句 人生格言 读书名言 名人名 励志名言 名言集 励志名言警句 爱情名言 激励名言 名言名句大全`
      }
      document.title = title
      // 如果有对应的h1元素，通过querySelector找到它并更新文本内容
      const h1Element = document.querySelector('h1')
      if (h1Element) {
        h1Element.textContent = h1
      }
      // 设置或更新 meta description 标签
      const existingDescriptionTag = document.querySelector('meta[name="description"]')
      const existingDescriptionTag1 = document.querySelector('meta[name="keywords"]')
      if (existingDescriptionTag) {
        existingDescriptionTag.setAttribute('content', description)
      } else {
        const newDescriptionTag = document.createElement('meta')
        newDescriptionTag.name = 'description'
        newDescriptionTag.content = description
        document.head.appendChild(newDescriptionTag)
      }
      if (existingDescriptionTag1) {
        existingDescriptionTag1.setAttribute('content', keywords)
      } else {
        const newDescriptionTag = document.createElement('meta')
        newDescriptionTag.name = 'keywords'
        newDescriptionTag.content = keywords
        document.head.appendChild(newDescriptionTag)
      }
    }
    onMounted(() => {
      fetchData() // 在组件挂载时手动调用一次
    })
    watch(() => [searchContent.value, searchType.value], updatePageTitle, {
      immediate: true
    })
    console.log('seatch', searchContent)
    return {
      list,
      searchContent,
      searchType,
      isLodaing
    }
  }
})
