import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "container home-page",
  style: {"margin-top":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_carousel_list = _resolveComponent("carousel-list")!
  const _component_column_list = _resolveComponent("column-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_carousel_list, { list: _ctx.carouseilist }, null, 8, ["list"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_column_list, { list: _ctx.list }, null, 8, ["list"])
    ])
  ], 64))
}