
import { defineComponent, PropType } from 'vue'
import { PostProps } from '@/testData'
export default defineComponent({
  props: {
    list: {
      required: true,
      // eslint-disable-next-line no-undef
      type: Array as PropType<PostProps[]>
    }
  },
  setup (props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const posts = props.list
    return {
      posts
    }
  }
})
