
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import Pagination from '@/components/Pagination.vue'
import store, { GlobalDataProps, Quotes } from '@/store'
import { useStore } from 'vuex'
// import * as bootstrap from 'bootstrap'
import { Toast } from 'bootstrap'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Pagination
  },
  name: 'QuotesList',
  props: {
    list: {
      type: Array as PropType<Quotes[]>,
      require: true
    },
    itemsPerPage: {
      type: Number,
      default: 12
    },
    isThemesPage: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore<GlobalDataProps>()
    const handleLikeClick = (id: string) => {
      store.dispatch('updateLikeNumber', id)
      // // Find the liked quote with the updated liker number
      const likedQuote = props.list?.find(quote => quote.id === id)
      // Check if the liker number is 1 and trigger the toast
      if (likedQuote?.likenumber === 0) {
        // const toastTrigger = document.getElementById('liveToastBtn')
        const toastLiveExample = document.getElementById('liveToast')
        if (toastLiveExample) {
          const toast = new Toast(toastLiveExample)
          toast.show()
        }
      }
      if (likedQuote && likedQuote?.likenumber >= 0) {
        const likeIcon = document.getElementById(`likeIcon_${id}`)
        if (likeIcon) {
          likeIcon.classList.add('text-danger')
        }
      }
    }
    const currentPage = ref(1)
    const displayedQuotes = computed(() => {
      const startIndex = (currentPage.value - 1) * props.itemsPerPage
      const endIndex = startIndex + props.itemsPerPage
      return props.list?.slice(startIndex, endIndex) || []
    })
    const totalPages = computed(() => Math.ceil((props.list?.length || 0) / props.itemsPerPage))
    console.log('quotestlist', displayedQuotes.value)
    const changePage = (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages.value) {
        currentPage.value = newPage
      }
    }
    const getColClass = () => {
      const totalQuotes = displayedQuotes.value.length
      // 如果只有一条数据，动态设置列的大小为 col-md-6 col-sm-12
      if (totalQuotes === 1) {
        return 'col-md-12 col-sm-12 mx-auto'
      }
      // 否则，使用原始的 col-md-3 col-sm-12
      return 'col-md-3 col-sm-12 mx-auto'
    }
    const sentences = [
      '这4句话,就像海边的贝壳,你永远不知道下一颗会是什么样子。',
      '这4句话,就像夜空中的星星,璀璨又神秘,每一句都闪烁着不同的光芒,引导我们探索心灵的迷雾,找到属于自己的方向。',
      '这4句话,就像命中注定的相知相惜,即使转瞬即逝也要感激这份缘分,学会珍视生命中的点点滴滴',
      '这4句话,就像命中注定的知音,即使匆匆相遇也要感激,并学会珍惜生活中所有美好的相遇。',
      '这4句话,就像与素未谋面之人的奇妙相遇,既惊喜又意外,让人明白要珍惜生命中所有相逢的缘分。',
      '这4句话,就像无心插柳柳成荫的邂逅,虽然随机却让人受益匪浅,要懂得珍惜每一次心静神怡。'
    ]
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        console.log('leng', text.slice(0, maxLength) + '...')
        return text.slice(0, maxLength) + '...'
      } else {
        console.log('leng', text.length)
        return text
      }
    }
    const removeParentheses = (author:string) => {
      // return author.replace(/[\s\u3000]*\([^)]*\)[\s\u3000]*/g, '').trim() + '。。。'
      // 全角括号
      return author.replace(/[\s\u3000]*（[^）]*）[\s\u3000]*/g, '').trim()
    }
    const QutoesByTheme = (themename: string) => {
      const type = 3
      // console.log('Clicked theme:', themename)
      // router.push({ name: 'themes' })
      store.dispatch('SearchQuotesByType', { content: themename, skip: 0, limit: 150 })
      router.push({ name: 'search', params: { searchcontent: themename, type: type } })
    }
    const searchQuotes = (author:string) => {
      const author1 = removeParentheses(author)
      const type = 1
      store.dispatch('SearchQuotesByAuthor', { content: author1, skip: 0, limit: 150 })
      router.push({ name: 'search', params: { searchcontent: author1, type: type } })
    }
    return {
      currentPage,
      displayedQuotes,
      totalPages,
      changePage,
      handleLikeClick,
      getColClass,
      sentences,
      truncateText,
      QutoesByTheme,
      searchQuotes,
      removeParentheses
    }
  }
})
