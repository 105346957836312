import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "validate-input-container pb-3" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      class: ["form-control", {'is-invalid': _ctx.inputRef.error}],
      value: _ctx.inputRef.val,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_2),
    (_ctx.inputRef.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.inputRef.message), 1))
      : _createCommentVNode("", true)
  ]))
}